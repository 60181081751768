<template>
  <div class="height1">
    <div class="iq-top-navbar">
      <el-page-header @back="$router.go(-1)" content="订单列表"> </el-page-header>
    </div>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm2" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm2.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search2" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="医生部门">
                <el-cascader :options="departmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm2.keyWord" type="text" size="small" placeholder="订单号/接诊医生/联系方式" clearable @keyup.enter.native="Search2"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search2()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields" worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="small" class="el-icon-download">导出当前页数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="small" class="el-icon-download">导出{{DataCount}}条数据</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="orderNum" label="订单编号" />
          <el-table-column prop="businessDepartmentName" label="所属业务组" align="center" />
          <el-table-column prop="businessName" label="所属业务员" align="center" />
          <el-table-column prop="doctorName" label="开方医生" align="center" />
          <el-table-column prop="doctorPhone" label="开方医生" align="center" />
          <el-table-column prop="departmentName" label="所属部门" align="center"></el-table-column>
          <el-table-column prop="totalPayMoney" label="订单总额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalPayMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="doctorDIstributionMoney" label="医生抽佣(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.doctorDIstributionMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="auditMoney" label="总审核费用(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.auditMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="platformCommissionMoney" label="平台抽佣(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.platformCommissionMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="orgTotalMoney" label="机构入账(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.orgTotalMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="paySuccessTime" label="收费时间" align="center" width="160px">
            <template slot-scope="scope">
              {{ scope.row.paySuccessTime | timefilters }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage2" :page-size="pageSize2" :current-page="pageIndex2" :total="dataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    查看订单    -->
    <el-dialog :visible.sync="orderDialog" width="75%">
      <el-form :model="searchForm2" :inline="true">
        <el-form-item>
          <el-date-picker v-model="searchForm2.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search2" clearable> </el-date-picker>
        </el-form-item>
        <el-form-item label="部门">
          <el-cascader :options="departmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="searchForm2.keyWord" type="text" size="small" placeholder="订单号/接诊医生/联系方式" clearable @keyup.enter.native="Search2"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="Search2()">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="orderData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="orderNum" label="订单编号" />
        <el-table-column prop="doctorName" label="开方医生" align="center" />
        <el-table-column prop="doctorPhone" label="开方医生" align="center" />
        <el-table-column prop="departmentName" label="所属部门" align="center"></el-table-column>
        <el-table-column prop="totalPayMoney" label="订单总额(元)" align="center">
          <template slot-scope="scope">
            {{ scope.row.totalPayMoney.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="doctorDIstributionMoney" label="医生抽佣(元)" align="center">
          <template slot-scope="scope">
            {{ scope.row.doctorDIstributionMoney.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="auditMoney" label="总审核费用(元)" align="center">
          <template slot-scope="scope">
            {{ scope.row.auditMoney.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="platformCommissionMoney" label="平台抽佣(元)" align="center">
          <template slot-scope="scope">
            {{ scope.row.platformCommissionMoney.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="orgTotalMoney" label="机构入账(元)" align="center">
          <template slot-scope="scope">
            {{ scope.row.orgTotalMoney.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="paySuccessTime" label="收费时间" align="center" width="160px">
          <template slot-scope="scope">
            {{ scope.row.paySuccessTime | timefilters }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="changePage2" :page-size="pageSize2" :current-page="pageIndex2" :total="dataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import { Department } from '@/components/HospitalDomain/Department.js'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      departmentUsersData: [],
      searchForm: {
        keyWord: '',
        datepicker: '',
        departmentId: 0,
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      searchForm2: {
        keyWord: '',
        datepicker: '',
        departmentId: 0,
      },
      id: 0,
      orderData: [],
      orderDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 15,
      departmentSumBindUsers: [],
      title: '订单明细',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'orderNum',
          callback: (val) => {
            return val.orderNum
          },
        },
        所属业务组: 'businessDepartmentName',
        所属业务员: 'businessName',
        开方医生: 'doctorName',
        开方医生: 'doctorPhone',
        所属部门: 'departmentName',
        '订单总额(元)': 'totalPayMoney',
        '医生抽佣(元)': 'doctorDIstributionMoney',
        '总审核费用(元)': 'auditMoney',
        '平台抽佣(元)': 'platformCommissionMoney',
        '机构入账(元)': 'orgTotalMoney',
        收费时间: {
          filed: 'paySuccessTime',
          callback: (val) => {
            var val = val.paySuccessTime
            return this.$fliters.timefilters(val)
          },
        },
      },
    }
  },
  mounted() {
    this.getDepartment()
    this.departmentSumBindUsers = JSON.parse(sessionStorage.getItem('bindUsers'))
    this.getList()
  },
  methods: {
    getDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.Department.GetDepartment(
        organizationId,
        function (data) {
          _this.departmentData = data.data
        },
        function (err) {
          _this.departmentData = []
        }
      )
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
        var item = _this.searchForm2
        if (_this.searchForm2.datepicker) {
          item.startTime = _this.searchForm2.datepicker[0] + ' 00:00:00'
          item.endTime = _this.searchForm2.datepicker[1] + ' 23:59:59'
        } else {
          item.startTime = ''
          item.endTime = ''
        }
        _this.OutpatientDomain.BusinessMZInputList(
          _this.departmentSumBindUsers,
          organizationId,
          item.keyWord,
          item.departmentId ? item.departmentId : 0,
          item.startTime,
          item.endTime,
          this.pageIndex2,
          _this.DataCount,
          -1,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          }
        )
      })
    },
    getList() {
      var _this = this
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var item = _this.searchForm2
      if (_this.searchForm2.datepicker) {
        item.startTime = _this.searchForm2.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm2.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.tableData = []
      _this.OutpatientDomain.BusinessMZInputList(
        _this.departmentSumBindUsers,
        organizationId,
        item.keyWord,
        item.departmentId ? item.departmentId : 0,
        item.startTime,
        item.endTime,
        this.pageIndex2,
        this.pageSize2,
        -1,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex2 = data.data.pageIndex
          _this.pageSize2 = data.data.pageSize
          _this.dataTotal2 = data.data.dataTotal
          _this.DataCount = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage2(pageIndex) {
      this.pageIndex2 = pageIndex
      this.getList()
    },
    Search2(event) {
      this.getList()
    },
    handleOption(e) {
      this.searchForm2.departmentId = e[e.length - 1]
      this.pageIndex2 = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
